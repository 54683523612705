<template>
  <ez-content>
    <ez-container>
      <ez-row>
        <ez-col>
          <ez-sub-header class="mb-3">
            <template slot="title">
              <div v-html="title"></div>
            </template>
            <template slot="filters">
              <ez-filter-export
                title
                colors="contacts"
                :filters="statisticsFilters"
                v-show="false" />
            </template>
          </ez-sub-header>
          <div v-if="loading" class="mb-3" style="margin-top: 240px;">
            <ez-preloader :stop="false" :value="true" />
          </div>
          <app-stub-box
            v-if="empty && !loading"
            :image="require('../../assets/icons/snooze.svg')"
            :title="$t('timeline.no-data-title')"
            :description="$t('timeline.no-data-description')"
            />
          <ez-timeline v-if="!empty && !loading">
            <ez-timeline-item v-for="(item, index) in displayItems"
              :key="index"
              :icon="item.icon"
              iconColor="#8095AA"
              :date="item.date"
              >
              <div v-html="item.meta.text"></div>
              </ez-timeline-item>
          </ez-timeline>
        </ez-col>
      </ez-row>
    </ez-container>
  </ez-content>
</template>

<script>
import get from 'lodash/get';
import * as getters from '@/store/timeline/getters';
import * as actions from '@/store/timeline/actions';
import contactMixin from '@/mixins/contact';
import localeMixin from '@/mixins/locale';
import userTime from '@/services/formatters/userTime';
import formatter from './format';

import eventIcons from './icons';
import eventActions from './actions';

const STORE_PREFIX = 'timeline';

const replacer = (value) => `<span>${value.replace('>', '&gt;').replace('<', '&lt;')}</span>`;

export default {
  name: 'AppTimeline',
  mixins: [contactMixin, localeMixin],
  data() {
    return {
      statisticsFilters: [
        {
          category: 'Personal Information',
          items: [
            {
              title: 'First Name',
              selected: false,
            },
            {
              title: 'Last Name',
              selected: false,
            },
            {
              title: 'Street Name',
              selected: false,
            },
            {
              title: 'ZIP Code',
              selected: false,
            },
            {
              title: 'Country',
              selected: false,
            },
            {
              title: 'Birthdate',
              selected: false,
            },
            {
              title: 'City',
              selected: false,
            },
          ],
        },
        {
          category: 'Contact Information',
          items: [
            {
              title: 'E-mail address',
              selected: false,
            },
            {
              title: 'Phone Number',
              selected: false,
            },
          ],
        },
        {
          category: 'Funnel Connections',
          items: [
            {
              title: 'Purchased Products',
              selected: false,
            },
            {
              title: 'Contact Type',
              selected: false,
            },
            {
              title: 'Visited Funnels',
              selected: false,
            },
            {
              title: 'Tags',
              selected: false,
            },
            {
              title: 'E-mail Open Rate',
              selected: false,
            },
            {
              title: 'E-mail Click Through',
              selected: false,
            },
            {
              title: 'Unique Funnel Sessions',
              selected: false,
            },
            {
              title: 'Number of Lessons Completed',
              selected: false,
            },
            {
              title: 'Lessons Completed',
              selected: false,
            },
            {
              title: 'Total Revenue Made',
              selected: false,
            },
            {
              title: 'Number of logins to memberarea',
              selected: false,
            },
            {
              title: 'Number of lessons completed',
              selected: false,
            },
            {
              title: 'Lessons Completed',
              selected: false,
            },
            {
              title: 'No. of Webinar Registration',
              selected: false,
            },
            {
              title: 'Webinars Attended',
              selected: false,
            },
            {
              title: 'No. of purchases through webinar',
              selected: false,
            },
          ],
        },
        {
          category: 'Activity',
          items: [
            {
              title: 'IP Address',
              selected: false,
            },
            {
              title: 'Custom',
              selected: false,
            },
          ],
        },
      ],
      title: this.$t('timeline.title'),
      pageTitle: this.$t('header.menuLinks.timeline'),
    };
  },
  created() {
    // this.getData();

    // load contact's timeline
    this.loadItems();
  },
  computed: {
    items() {
      const data = this.$store.getters[`${STORE_PREFIX}/${getters.ITEMS}`];
      // process meta field - convert it to object from JSON string
      const result = (data || []).map((item) => {
        const attributes = get(item, 'attributes') || {};
        const meta = get(item, 'attributes.meta', null);
        let metaObj = null;
        if (meta != null) {
          // eslint-disable-next-line
          try { metaObj = JSON.parse(meta); } catch (err) { }
        }
        return {
          ...item,
          attributes: {
            ...attributes,
            meta: metaObj || meta,
          },
        };
      });
      return result;
    },
    displayItems() {
      return this.items.map((item) => {
        const type = get(item, 'attributes.type');

        const icon = eventIcons[type] ? eventIcons[type] : 'question-circle';

        const action = get(eventActions, `${this.locale}[${type}]`)
          || get(eventActions, `default[${type}]`)
          || 'Unknown action';

        const dateStr = get(item, 'attributes.dt-create', null);

        const result = {
          icon,
          meta: {
            text: formatter(action, { ...item, contactname: this.currentContactName }, replacer),
          },
          date: userTime(dateStr, { timeFormat: 'ddd MMM DD YYYY' }),
        };

        return result;
      });
    },
    totalItems() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_ITEMS}`];
    },
    empty() {
      return !(this.items || []).length;
      // return this.totalItems === 0;
    },
    totalPages() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.TOTAL_PAGES}`];
    },
    loading() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.LOADING}`];
    },
    pageSize() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_SIZE}`];
    },
    pageNumber() {
      return this.$store.getters[`${STORE_PREFIX}/${getters.PAGE_NUMBER}`];
    },
    enabled() {
      return this.currentContact != null;
    },
  },
  methods: {
    // load data
    loadItems(params = {}) {
      const actualParams = {
        contactId: this.currentContactId,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...params,
      };
      this.$store.dispatch(
        `${STORE_PREFIX}/${actions.GET_ITEMS}`,
        actualParams,
      );
    },
  },
};
</script>

<style lang="scss">
.ez-timeline-item {

  &__content {
    padding-top: 20px!important;
    padding-right: 20px!important;
    padding-bottom: 10px!important;
  }

  &__text {
    span {
      color: #00ADAB;
    }
  }

  &:nth-child(even) {
    padding-left: 100px;
  }
  &:nth-child(odd) {
    padding-right: 100px;
  }

  @media only screen and (max-width: 1024px) {
    &:nth-child(even) {
      padding-left: 50px;
    }
    &:nth-child(odd) {
      padding-right: 50px;
    }
  }

  @media only screen and (max-width: 640px) {
    &:nth-child(even) {
      padding-left: 8px;
    }
    &:nth-child(odd) {
      padding-right: 8px;
    }
  }
}
</style>
