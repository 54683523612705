import * as eventTypes from './types';

export default {
  [eventTypes.EVENT_TYPE_OPTIN]: 'edit',
  [eventTypes.EVENT_TYPE_LEFT_PAGE]: 'sign-out',
  [eventTypes.EVENT_TYPE_VISIT_PAGE]: 'eye',
  [eventTypes.EVENT_TYPE_FILL_FORM]: 'pen',
  [eventTypes.EVENT_TYPE_CLICK_YES]: 'thumbs-up',
  [eventTypes.EVENT_TYPE_CLICK_NO]: 'thumbs-down',

  [eventTypes.EVENT_TYPE_WEBINAR_REGISTRATION]: 'calendar-alt',
  [eventTypes.EVENT_TYPE_WEBINAR_VISIT]: 'video',
  [eventTypes.EVENT_TYPE_WEBINAR_CTA_SHOWN]: 'dollar-sign',
  [eventTypes.EVENT_TYPE_WEBINAR_CTA_CLICK]: 'hand-pointer',
  // todo - insert icon from design
  [eventTypes.EVENT_TYPE_WEBINAR_LEAVE]: 'sign-out',

  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_VISIT]: 'video',
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_CTA_SHOWN]: 'dollar-sign',
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_CTA_CLICK]: 'hand-pointer',
  // todo - insert icon from design
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_REGISTRATION]: 'calendar-alt',

  [eventTypes.EVENT_TYPE_MAIL_RECEIVED]: 'envelope',
  [eventTypes.EVENT_TYPE_MAIL_OPENED]: 'envelope-open',
  [eventTypes.EVENT_TYPE_MAIL_CLICKED]: 'mouse-pointer',

  [eventTypes.EVENT_TYPE_PURCHASE_REFUNDED]: 'money-bill-wave',

  [eventTypes.EVENT_TYPE_ACADEMY_JOIN]: 'university',
  [eventTypes.EVENT_TYPE_COURSE_STARTED]: 'book-reader',
  [eventTypes.EVENT_TYPE_COURSE_COMPLETED]: 'user-graduate',
  // todo - insert icon from design
  [eventTypes.EVENT_TYPE_ACADEMY_LESSON_STARTED]: 'book-reader',
  // todo - insert icon from design
  [eventTypes.EVENT_TYPE_ACADEMY_LESSON_COMPLETED]: 'user-graduate',

  [eventTypes.EVENT_TYPE_PURCHASE]: 'credit-card',
  // todo - insert icon from design
  [eventTypes.EVENT_TYPE_CONTACT_CONFIRMED]: 'check',
};
