export const EVENT_TYPE_OPTIN = 1; // Submit form
export const EVENT_TYPE_LEFT_PAGE = 2;
export const EVENT_TYPE_VISIT_PAGE = 10;
// If isset fill form type and option doesnt exist u should use this type
export const EVENT_TYPE_FILL_FORM = 3;
export const EVENT_TYPE_CLICK_YES = 4;
export const EVENT_TYPE_CLICK_NO = 5;

export const EVENT_TYPE_WEBINAR_REGISTRATION = 6;
export const EVENT_TYPE_WEBINAR_VISIT = 7;
export const EVENT_TYPE_WEBINAR_CTA_SHOWN = 8;
export const EVENT_TYPE_WEBINAR_CTA_CLICK = 9;
export const EVENT_TYPE_WEBINAR_LEAVE = 25;

export const EVENT_TYPE_REPLY_WEBINAR_VISIT = 21;
export const EVENT_TYPE_REPLY_WEBINAR_CTA_SHOWN = 22;
export const EVENT_TYPE_REPLY_WEBINAR_CTA_CLICK = 23;
export const EVENT_TYPE_REPLY_WEBINAR_REGISTRATION = 24;
export const EVENT_TYPE_REPLY_WEBINAR_LEAVE = 26;

export const EVENT_TYPE_MAIL_RECEIVED = 11;
export const EVENT_TYPE_MAIL_OPENED = 12;
export const EVENT_TYPE_MAIL_CLICKED = 13;

export const EVENT_TYPE_PURCHASE_REFUNDED = 14;

export const EVENT_TYPE_ACADEMY_JOIN = 15;
export const EVENT_TYPE_COURSE_STARTED = 16;
export const EVENT_TYPE_COURSE_COMPLETED = 17;

export const EVENT_TYPE_ACADEMY_LESSON_STARTED = 19;
export const EVENT_TYPE_ACADEMY_LESSON_COMPLETED = 20;
export const EVENT_TYPE_CONTACT_CONFIRMED = 18;

export const EVENT_TYPE_PURCHASE = 27;
