import * as eventTypes from './types';

// default (EN) locale
const en = {
  [eventTypes.EVENT_TYPE_OPTIN]:
    '%contactname filled a form at step %relationships.step.attributes.name in %relationships.funnel.attributes.name',
  [eventTypes.EVENT_TYPE_LEFT_PAGE]:
    '%contactname dropped out of the funnel %relationships.funnel.attributes.name at step %relationships.step.attributes.name',
  [eventTypes.EVENT_TYPE_VISIT_PAGE]:
    '%contactname visited step %relationships.step.attributes.name in funnel %relationships.funnel.attributes.name',
  [eventTypes.EVENT_TYPE_FILL_FORM]:
    "%contactname filled the orderform in %relationships.funnel.attributes.name, but didn't purchase",
  [eventTypes.EVENT_TYPE_CLICK_YES]:
    '%contactname clicked YES on upsell with product %attributes.meta.purchase.product_name in funnel %relationships.funnel.attributes.name',
  [eventTypes.EVENT_TYPE_CLICK_NO]:
    '%contactname declined %attributes.meta.purchase.product_name in funnel %relationships.funnel.attributes.name',

  [eventTypes.EVENT_TYPE_WEBINAR_REGISTRATION]:
    '%contactname registered to webinar %relationships.webinar.attributes.name',
  [eventTypes.EVENT_TYPE_WEBINAR_VISIT]:
    '%contactname attendeed to webinar %relationships.webinar.attributes.name',
  [eventTypes.EVENT_TYPE_WEBINAR_CTA_SHOWN]:
    '%contactname has seen the CTA in webinar %relationships.webinar.attributes.name',
  [eventTypes.EVENT_TYPE_WEBINAR_CTA_CLICK]:
    '%contactname webinar cta clickclicked on CTA in webinar %relationships.webinar.attributes.name',
  // todo: insert event text
  [eventTypes.EVENT_TYPE_WEBINAR_LEAVE]: '%contactname left the webinar %relationships.webinar.attributes.name',

  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_VISIT]:
    '%contactname attendeed to webinar replay %relationships.webinar.attributes.name',
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_CTA_SHOWN]:
    '%contactname has seen the CTA in webinar replay %relationships.webinar.attributes.name',
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_CTA_CLICK]:
    '%contactname clicked on CTA in webinar replay %relationships.webinar.attributes.name',
  // todo
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_REGISTRATION]: '%contactname registered to webinar replay %relationships.webinar.attributes.name',
  // todo
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_LEAVE]: '%contactname has left the webinar replay of %relationships.webinar.attributes.name',

  [eventTypes.EVENT_TYPE_MAIL_RECEIVED]: '%contactname recieved the mail %mail_subject',
  [eventTypes.EVENT_TYPE_MAIL_OPENED]: '%contactname opened the mail %mail_subject',
  [eventTypes.EVENT_TYPE_MAIL_CLICKED]:
    '%contactname clicked a link in the mail %mail_subject',

  [eventTypes.EVENT_TYPE_PURCHASE_REFUNDED]:
    '%contactname refunded the product %relationships.funnel.attributes.name',

  [eventTypes.EVENT_TYPE_ACADEMY_JOIN]:
    '%contactname joined the academy %relationships.academy.attributes.name',
  [eventTypes.EVENT_TYPE_COURSE_STARTED]:
    '%contactname started the course %relationships.course.attributes.name in academy %relationships.academy.attributes.name',
  [eventTypes.EVENT_TYPE_COURSE_COMPLETED]:
    '%contactname completed the course %relationships.course.attributes.name in academy %relationships.academy.attributes.name',
  // todo
  [eventTypes.EVENT_TYPE_ACADEMY_LESSON_STARTED]: '%contactname started the lesson %relationships.course-lesson.attributes.name in course %relationships.course.attributes.name',
  [eventTypes.EVENT_TYPE_ACADEMY_LESSON_COMPLETED]: '%contactname completed the lesson %relationships.course-lesson.attributes.name in course %relationships.course.attributes.name',

  [eventTypes.EVENT_TYPE_PURCHASE]:
    '%contactname purchased %attributes.meta.purchase.product_name in funnel %relationships.funnel.attributes.name',
  // todo
  [eventTypes.EVENT_TYPE_CONTACT_CONFIRMED]: '%contactname has confirmed his/her email adress',
};

const de = {
  [eventTypes.EVENT_TYPE_OPTIN]:
    '%contactname füllte ein Formular beim Schritt %relationships.step.attributes.name in %relationships.funnel.attributes.name aus',
  [eventTypes.EVENT_TYPE_LEFT_PAGE]:
    '%contactname verließ den Funnel %relationships.funnel.attributes.name beim Schritt %relationships.step.attributes.name',
  [eventTypes.EVENT_TYPE_VISIT_PAGE]:
    '%contactname besuchte %relationships.step.attributes.name im Funnel %relationships.funnel.attributes.name',
  [eventTypes.EVENT_TYPE_FILL_FORM]:
    '%contactname füllte das Bestellformular im Funnel %relationships.funnel.attributes.name aus, ohne zu bestellen',
  [eventTypes.EVENT_TYPE_CLICK_YES]:
    '%contactname klickte JA beim Upsell mit dem Produkt %attributes.meta.purchase.product_name im Funnel %relationships.funnel.attributes.name',
  [eventTypes.EVENT_TYPE_CLICK_NO]:
    '%contactname lehnte %attributes.meta.purchase.product_name im Funnel %relationships.funnel.attributes.name ab',

  [eventTypes.EVENT_TYPE_WEBINAR_REGISTRATION]:
    '%contactname hat sich für das Webinar %relationships.webinar.attributes.name registriert',
  [eventTypes.EVENT_TYPE_WEBINAR_VISIT]:
    '%contactname hat am Webinar %relationships.webinar.attributes.name teilgenommen',
  [eventTypes.EVENT_TYPE_WEBINAR_CTA_SHOWN]:
    '%contactname hat die CTA in Webinar %relationships.webinar.attributes.name gesehen',
  [eventTypes.EVENT_TYPE_WEBINAR_CTA_CLICK]:
    '%contactname hat die CTA in Webinar %relationships.webinar.attributes.name geklickt',
  // todo: insert event text
  [eventTypes.EVENT_TYPE_WEBINAR_LEAVE]: '%contactname hat das Webinar %relationships.webinar.attributes.name verlassen',

  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_VISIT]:
    '%contactname hat an der Webinaraufzeichnung %relationships.webinar.attributes.name teilgenommen',
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_CTA_SHOWN]:
    '%contactname hat die CTA in der Webinaraufzeichnung %relationships.webinar.attributes.name gesehen',
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_CTA_CLICK]:
    '%contactname hat die CTA in der Webinaraufzeichnung %relationships.webinar.attributes.name geklickt',
  // todo
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_REGISTRATION]: '%contactname hat sich für die Webinaraufzeichnung %relationships.webinar.attributes.name registriert',
  // todo
  [eventTypes.EVENT_TYPE_REPLY_WEBINAR_LEAVE]: '%contactname hat die Webinaraufzeichung %relationships.webinar.attributes.name verlassen',

  [eventTypes.EVENT_TYPE_MAIL_RECEIVED]: '%contactname hat die Mail %mail_subject erhalten',
  [eventTypes.EVENT_TYPE_MAIL_OPENED]: '%contactname hat die Mail %mail_subject geöffnet',
  [eventTypes.EVENT_TYPE_MAIL_CLICKED]:
    '%contactname hat einen Link in der Mail %mail_subject geklickt',

  [eventTypes.EVENT_TYPE_PURCHASE_REFUNDED]:
    '%contactname hat das Produkt %relationships.funnel.attributes.name zurückgegeben',

  [eventTypes.EVENT_TYPE_ACADEMY_JOIN]:
    '%contactname hat die Academy %relationships.academy.attributes.name besucht',
  [eventTypes.EVENT_TYPE_COURSE_STARTED]:
    '%contactname hat den Kurs %relationships.course.attributes.name in der Academy %relationships.academy.attributes.name gestartet',
  [eventTypes.EVENT_TYPE_COURSE_COMPLETED]:
    '%contactname hat den Kurs %relationships.course.attributes.name in der Academy %relationships.academy.attributes.name abgeschlossen',
  // todo
  [eventTypes.EVENT_TYPE_ACADEMY_LESSON_STARTED]: '%contactname hat die Lektion %relationships.course-lesson.attributes.name im Kurs %relationships.course.attributes.name gestartet',
  [eventTypes.EVENT_TYPE_ACADEMY_LESSON_COMPLETED]: '%contactname hat die Lektion %relationships.course-lesson.attributes.name im Kurs %relationships.course.attributes.name abgeschlossen',

  [eventTypes.EVENT_TYPE_PURCHASE]:
    '%contactname bestellte %attributes.meta.purchase.product_name im Funnel %relationships.funnel.attributes.name',
  // todo
  [eventTypes.EVENT_TYPE_CONTACT_CONFIRMED]: '%contactname hat seine/ihre E-Mail Adresse bestätigt',
};

export default {
  // use en as default
  default: en,

  // all supported locales
  en,
  en_US: en,
  de,
  de_DE: de,
};
